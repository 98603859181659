import React from 'react';
import YOUTUBE from "../assets/svg/youtube.svg"
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';



function convertYouTubeLinkIntoEmbedLink(link) {
    // Define the patterns to match
    const patterns = [
        /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/,
        /(?:https?:\/\/)?(?:www\.)?youtu\.be\/([a-zA-Z0-9_-]{11})/
    ];

    if (link.includes('shorts')) {
        const splittedLink = link.split('shorts/')
        console.log(link.split('shorts/')[1], 'link_link_link')
        const embeddedLink = `https://www.youtube.com/embed/${splittedLink[1]}`;
        return embeddedLink;
    }

    // Check if the link matches any of the patterns
    for (var pattern of patterns) {
        var match = link.match(pattern);
        if (match) {
            var videoId = match[1];
            // varruct the new link with "embed"
            var embeddedLink = `https://www.youtube.com/embed/${videoId}`;
            console.log("link => ", link)
            console.log("embeddedLink => ", embeddedLink)
            return embeddedLink;
        }
    }

    // If no match is found, return the original link
    console.log("link => ", link)
    return link;
}


function VideoModal({ videoId, videoTitle }) {
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);

    return (
        <>
            <button className="btn" onClick={() => setShow(true)}>
                <img src={YOUTUBE} width="120" alt={videoTitle || ""} />
            </button>

            <Modal className='border-0' show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>

                <div className="position-absolute z-1 top-0 end-0 p-2 me-3 mt-3 bg-white rounded">
                    <button type="button" className="btn-close" aria-label="Close" onClick={() => setShow(false)}></button>
                </div>

                <Modal.Header className='border-0 visually-hidden'>
                    {/* <Modal.Title></Modal.Title> */}
                    <h2 className="modal-title">{videoTitle || ""}</h2>
                </Modal.Header>

                <Modal.Body className='overflow-hidden p-0 border-0'>
                    <iframe id='youriframeid' title="YouTube Video" style={{ width: "100%", height: "100%" }}
                        // src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`}></iframe>
                        src={convertYouTubeLinkIntoEmbedLink(videoId)}></iframe>
                </Modal.Body>

            </Modal>
        </>
    );
}

export default VideoModal;