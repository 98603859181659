import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useToast } from '../../ContextAPI/Components/notify';
import profilePhoto from '../../assets/user_placeholder.png'
import { FILE_URL } from '../../config';
import { useAuth } from '../../ContextAPI/Components/auth';

const DashHead = () => {
    const { user } = useAuth()
    const { toastAlert } = useToast();
    const navigate = useNavigate();

    const logout = () => {
        localStorage.removeItem('id');
        toastAlert("You have been successfully logged out", "success")
        navigate('/')
    }

    console.log("login user", user);

    return <>
        <div className='d-flex flex-column justify-content-center align-items-center py-5'>
            <h1 className=''>Welcome <span style={{ textTransform: "capitalize"}}>{user?.username || "Yarley"}</span> to Zintunes</h1>
            <div className=''>
                <img src={
                    (typeof user?.img != "undefined" && user?.img != null && user?.img != "") ?
                        `${FILE_URL}/${user?.img}`
                        :
                        profilePhoto
                }
                    className='rounded-circle'
                    alt="Your profile picture"
                    width={"100px"}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = profilePhoto;
                    }}
                />
            </div>
            <p className=''>Setup your Zintune experience below</p>
            <a href='#' onClick={() => logout()}>Logout</a>
        </div>
    </>
}

export default DashHead
