import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/dashboard-components/Sidebar'
import DashHead from '../../components/dashboard-components/dashHead'
import photo from '../../assets/svg/photo.svg'
import { getPCA, updatePCA } from '../../ContextAPI/APIs/api'
import { useToast } from '../../ContextAPI/Components/notify'
import ImageComp from '../../components/dashboard-components/ImageComp'
import LayoutDash from '../../components/LayoutDash'
import { useLoader } from '../../ContextAPI/Components/loaderContext'
import { useAuth } from '../../ContextAPI/Components/auth'



const PromotionalCallToAction = () => {

    const { toastAlert } = useToast()
    const { showLoader, hideLoader } = useLoader()


    const [img, setimg] = useState('')
    const [title, settitle] = useState(null)
    const [linkLabel, setlinkLabel] = useState(null)
    const [fileName, setFileName] = useState(null)
    const [link, setlink] = useState(null)
    const { user, getLoginUser } = useAuth();



    const getData = async () => {
        showLoader()
        let thisUser = user;
        if (thisUser == null) thisUser = await getLoginUser()

        const res = await getPCA({ type: thisUser.username });
        const data = res.message;

        if (!data) { }
        else {
            setlinkLabel(data.label);
            settitle(data.title);
            setlink(data.link)
            setFileName({ name: data.img })
            setimg({ name: data.img })
        }

        hideLoader()

    }

    const submit = async (e) => {
        e.preventDefault();
        showLoader()

        const myData = new FormData();
        myData.append('label', linkLabel);
        myData.append('link', link);
        myData.append('title', title);
        myData.append('img', fileName?.name || "");
        myData.append('myfile', img);

        const res = await updatePCA(myData);

        console.log(res);

        toastAlert(res.message, res.success ? "success" : "error")
        await getData();
        hideLoader()

    }

    useEffect(() => {
        getData();

    }, [])




    return <>

        <LayoutDash>
            <h2>Promotional Call to Action</h2>
            <form className="d-grid gap-3 my-3" action="">
                <div className="form-floating">
                    <input type="text" className=' form-control' name="title" id="title" value={title} onChange={(e) => settitle(e.target.value)} placeholder='Title' required />
                    <label for="title">Title</label>
                </div>
                <div className="form-floating">
                    <input type="text" className=' form-control' name="linkLabel" id="linkLabel" value={linkLabel} onChange={(e) => setlinkLabel(e.target.value)} placeholder='Link Label' required />
                    <label for="linkLabel">Link Label</label>
                </div>
                <div className="form-floating">
                    <input type="text" className=' form-control' name="link" id="link" value={link} onChange={(e) => setlink(e.target.value)} placeholder='Link' required />
                    <label for="link">Link</label>
                </div>


                <div class="input-group overflow-hidden">
                    <input type="file" className="form-control" id="profilePhoto" onChange={(e) => setimg(e.target.files[0])} required />

                    <ImageComp myfile={img} setmyfile={setimg} setFileName={setFileName} forr={'profilePhoto'} text={'Upload promotional call to action photo'} />

                </div>

                <button onClick={(e) => submit(e)} className='btn btn-primary'>Save</button>
            </form>
        </LayoutDash>

    </>
}

export default PromotionalCallToAction
