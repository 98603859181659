import React, { useEffect, useReducer, useState } from 'react'
import Sidebar from '../../components/dashboard-components/Sidebar'
import DashHead from '../../components/dashboard-components/dashHead'
import { useToast } from '../../ContextAPI/Components/notify'
import { getLayout, updateLayout, updateLayoutImg } from '../../ContextAPI/APIs/api'
import { useLoader } from '../../ContextAPI/Components/loaderContext'
import ImageComp from '../../components/dashboard-components/ImageComp'
import { truncateAndFormatText } from '../../hooks/truncateText'
import { useAuth } from '../../ContextAPI/Components/auth'

const layoutTemp = [
    {
        order: 1,
        name: 'Social and Email',
        active: false,
        img: '',
    },
    {
        order: 2,
        name: 'Promotional CTA',
        active: false,
        img: '',
    },
    {
        order: 3,
        name: 'Promotional Video',
        active: false
    },
    {
        order: 4,
        name: 'Releases',
        active: false
    }
]



const AdminLayout = () => {
    const { toastAlert } = useToast();
    const { loader, showLoader, hideLoader } = useLoader()
    const { user, getLoginUser } = useAuth();



    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const [layout, setlayout] = useState([])
    const [socialImg, setsocialImg] = useState('')
    const [promotionalimg, setpromotionalimg] = useState('')



    const getData = async () => {
        showLoader();

        let thisUser = user;
        if (thisUser == null) thisUser = await getLoginUser()

        const res = await getLayout({ type: thisUser.username });
        const data = res.message

        console.log(data, 'datadatadata');

        if (!res.success || data.length < 1) {
            setlayout(layoutTemp)
        } else {
            const updated = data.map(e => {
                return { ...e, img: { name: e.img } }
            })
            setlayout(updated);
        }



            hideLoader();

    }

    const submit = async () => {
        showLoader();

        const nonFileData = layout.map((e, i) => {
            return { _id: e._id, order: e.order, name: e.name, active: e.active, img: e.img ? e.img.name : '' }
        })
        console.log(nonFileData, 'nonfile');

        const res = await updateLayout(nonFileData)

        // const findSocialEmail = layout.find(e => e.name == 'Social and Email')


        if (socialImg || socialImg.length > 0) {
            const socialimgFormData = new FormData();
            socialimgFormData.append('name', 'Social and Email');
            socialimgFormData.append('myfile', socialImg);
            const socialImgRes = await updateLayoutImg(socialimgFormData);

        }

        // const findPromotionalCTA = layout.find(e => e.name == 'Promotional CTA')


        if (promotionalimg || promotionalimg.length > 0) {
            const promoimgFormData = new FormData();
            promoimgFormData.append('name', 'Promotional CTA');
            promoimgFormData.append('myfile', promotionalimg);
            const promotionalimgRes = await updateLayoutImg(promoimgFormData);
        }

        if (res.success) await getData()

        hideLoader();

        toastAlert(res.message, res.success ? "success" : "error")

    }

    const setToggle = (order, value) => {

        const nextLayout = layout.map(item => {
            if (item.order === order) {
                return {
                    ...item,
                    active: value,
                };
            } else {
                return item;
            }
        });

        // Re-render with the new array
        setlayout(nextLayout);

    }

    const setBgImg = (img, name) => {

        const updatedLayout = layout.map(e => {
            if (((e.name).toLowerCase() === 'social and email') && (name.toLowerCase() === 'social and email')) {
                setsocialImg(img)
                return { ...e, img: { name: `${img ? img.name : ''}` } }
            }
            else if (((e.name).toLowerCase() === "promotional cta") && (name.toLowerCase() === 'promotional cta')) {
                setpromotionalimg(img)
                return { ...e, img: { name: `${img ? img.name : ''}` } }
            }
            else return e;
        })

        setlayout(updatedLayout)
        forceUpdate();
    }

    const contentDown = async (i, arr, setArr, name) => {
        const temp = arr;


        let index1Value = temp[i];
        let index2Value = temp[i + 1];
        let index1id = temp[i].order;
        let index2id = temp[i + 1].order;


        temp[i] = index2Value;
        temp[i + 1] = index1Value;

        temp[i].order = index1id;
        temp[i + 1].order = index2id;

        // console.log(index1id, '11111111111111111111111111111');
        // console.log(index2id, '222222222222222222222222222222');

        // console.log(temp);

        setArr(temp)

        if (i == (temp.length - 2)) {
            window.document.getElementById(`${name}Top${temp.length - 1}`).focus()
        } else {
            window.document.getElementById(`${name}Down${i + 1}`).focus()
        }

        forceUpdate()

    }

    const contentUp = (i, arr, setArr, name) => {
        const temp = arr;

        // console.log(temp);

        let index1Value = temp[i];
        let index2Value = temp[i - 1];
        let index1id = temp[i].order;
        let index2id = temp[i - 1].order;


        temp[i] = index2Value;
        temp[i - 1] = index1Value;
        temp[i].order = index1id;
        temp[i - 1].order = index2id;



        setArr(temp)

        if (i == 1) {
            window.document.getElementById(`${name}Down0`).focus()
        } else {
            window.document.getElementById(`${name}Top${i - 1}`).focus()
        }

        forceUpdate()
    }


    useEffect(() => {
        getData()
    }, [])




    return <>

        <section>

            <DashHead />

            <div className="container-admin">

                <div className="row mb-5">
                    <div className="col-lg-4">
                        <Sidebar />
                    </div>
                    <div className="col-lg d-flex flex-column gap-3">
                        <h2>Layout</h2>
                        <div className='d-flex flex-column gap-3 p-3 bg-body-secondary rounded-3'>
                            {
                                layout.map((e, i) => {

                                    return <div key={e.order} className='d-flex p-3 shadow rounded-3 bg-white min-h-8rem'>

                                        <div className='d-flex flex-column w-100 justify-content-center'>
                                            <h3 className='fw-normal'>{e.name}</h3>
                                            <div class="form-check form-switch">
                                                <input class="form-check-input" type="checkbox" checked={e.active} role="switch" id={`flexSwitchCheckDefault${e.order}`} onChange={(E) => setToggle(e.order, E.target.checked)} />
                                                <label class="form-check-label" for={`flexSwitchCheckDefault${e.order}`}></label>
                                            </div>
                                        </div>
                                        {
                                            e.name == 'Promotional Video' || e.name == 'Releases' ? '' :
                                                <div className='d-flex align-items-center'>
                                                    <div class="input-group overflow-hidden">
                                                        <input type="file" className="form-control" id={`backgroundPhoto${e.order}`} onChange={(E) => setBgImg(E.target.files[0], e.name)} required />


                                                        {
                                                            !e?.img || e?.img.name == '' ?
                                                                <label className="input-group-text" for={`backgroundPhoto${e.order}`}>
                                                                    <span className='icon-photo'></span>
                                                                    {e.name == 'Social and Email' ?
                                                                        "Background Photo"

                                                                        :
                                                                        "Promotional Photo"
                                                                    }
                                                                </label>
                                                                :
                                                                <div className="input-group-text" for={`backgroundPhoto${e.order}`}>
                                                                    <span className='icon-photo'></span>
                                                                    <button className='btn btn-link-danger p-0' onClick={() => setBgImg('', e.name)}>Remove "{truncateAndFormatText(e?.img.name, 4)}"</button>
                                                                </div>

                                                        }

                                                    </div>
                                                </div>
                                        }

                                        <div className='d-flex align-items-center'>
                                            <div className='order-controls d-flex flex-column '>
                                                <button type='button' id={`layoutTop${i}`} className={`icon-btn icon-btn-primary`} disabled={i == 0 ? true : false} aria-label={`Move ${e.name} Up`} onClick={i == 0 ? () => { return } : () => contentUp(i, layout, setlayout, 'layout')}>
                                                    <span className='icon-top-arrow'></span>
                                                </button>
                                                <button type='button' id={`layoutDown${i}`} className={`icon-btn icon-btn-primary`} disabled={i == (layout.length - 1) ? true : false} aria-label={`Move ${e.name} Down`} onClick={i == (layout.length - 1) ? () => { return } : () => contentDown(i, layout, setlayout, 'layout')}>
                                                    <span className='icon-down-arrow'></span>
                                                </button>
                                            </div>
                                        </div>

                                    </div>


                                })
                            }
                        </div>

                        {
                            !loader &&

                            < div className='d-grid'>
                                <button onClick={() => submit()} className='btn btn-primary w-100'>Save</button>
                            </div>
                        }


                    </div>
                </div>
            </div>

        </section >
    </>
}

export default AdminLayout
