import { useEffect, useReducer, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { DeleteSong, GetAllArtists, GetSongData, LinkAlbumWithSong, UpdateSong, createSong, getAllAlbumsName, linkArtists, linkCredits } from "../../ContextAPI/APIs/api";
import { useToast } from "../../ContextAPI/Components/notify"
import { useLoader } from "../../ContextAPI/Components/loaderContext";
import Sidebar from "../../components/dashboard-components/Sidebar";
import DashHead from "../../components/dashboard-components/dashHead";

//typeahead
import TypeAhead from "../../components/TypeAhead";
import TypeAheadLoops from "../../components/TypeAheadLoops";
import Video from "./AddSongComps/Video";
import Lyrics from "./AddSongComps/Lyrics";
import Credits from "./AddSongComps/Credits";
import Artists from "./AddSongComps/Artists";
import ImageComp from "../../components/dashboard-components/ImageComp";
import { useSidebar } from "../../ContextAPI/Components/sidebar";
import { moveElementOnScreenTop, scrollToElement } from "../../helpers/util";
import { useAuth } from "../../ContextAPI/Components/auth";



const data = ["Areeb", "Osama", "Saim"];
const streamingLinksTemplate = {
  socialLabel: "",
  youtube: "",
  spotify: "",
  amazonMusic: "",
  tidal: "",
  soundcloud: "",
  podcastToggle: false,
  podcastYoutube: "",
  podcastSpotify: "",
  podcastApple: "",
  podcastAmazonMusic: "",
}

const AddSong = () => {
  const navigate = useNavigate();
  const { user } = useAuth()
  const { songID } = useParams()
  const { getSongsAndAlbums } = useSidebar()
  const { hash, pathname } = useLocation();
  const { toastAlert } = useToast();
  const { loader, showLoader, hideLoader } = useLoader()

  const router = useNavigate()

  if ((pathname == '/admin/edit-song/' || pathname == '/admin/edit-song') && (!hash)) {
    toastAlert('Invalid Request', "error");
    navigate('/admin/add-new')
  }


  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const [songData, setsongData] = useState(null);
  const [_id, set_id] = useState(null);
  const [Title, setTitle] = useState("");
  const [Type, setType] = useState("lyrical");
  const [fileName, setFileName] = useState(null);
  const [artistNameList, setArtistNameList] = useState([]);
  const [Artwork, setArtwork] = useState(null);
  const [Album, setAlbum] = useState([]);
  const [StreamingLinks, setStreamingLinks] = useState(streamingLinksTemplate);


  let socialLinks = [
    {
      icon: "youtube",
      label: "Youtube",
    },
    {
      icon: "spotify",
      label: "Spotify",
    },
    {
      icon: "apple-music",
      label: "Apple Music",
    },
    {
      icon: "tidal",
      label: "Tidal",
    },
    {
      icon: "amazon-music",
      label: "Amazon Music",
    },
    {
      icon: "soundcloud",
      label: "Soundcloud",
    },
  ];

  const [allAlbums, setallAlbums] = useState([
    {
      id: 1,
      name: "Album One",
    },
    {
      id: 2,
      name: "Album Two",
    },
    {
      id: 3,
      name: "Album Three",
    },
    {
      id: 4,
      name: "Album Four",
    },
    {
      id: 5,
      name: "Album Five",
    },
  ]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------

  // Create a song
  const submitData = async (e) => {
    e.preventDefault();
    showLoader()
    const myForm = new FormData()
    myForm.append("title", Title)
    myForm.append("type", Type)
    myForm.append("myfile", Artwork)
    myForm.append("img", fileName?.name || "")



    const res = await createSong(myForm)
    if (res.success) {
      set_id(res.id)
      navigate(`/admin/edit-song/${res.slug}`)
      getSongsAndAlbums()
    }

    console.log("res.message", res.message);
    toastAlert(res.message, res.success ? "success" : "error")
    hideLoader()
  };

  //  Update A Song
  const updateSong = async (e) => {
    e.preventDefault();
    showLoader()

    const myForm = new FormData()
    myForm.append("slug", songData.slug)
    myForm.append("_id", _id)
    myForm.append("title", Title)
    myForm.append("type", Type)
    myForm.append("img", fileName.name)
    myForm.append("myfile", Artwork)
    myForm.append("youtube", StreamingLinks["youtube"])
    myForm.append("spotify", StreamingLinks["spotify"])
    myForm.append("appleMusic", StreamingLinks["apple-music"])
    myForm.append("tidal", StreamingLinks["tidal"])
    myForm.append("amazonMusic", StreamingLinks["amazon-music"])
    myForm.append("soundCloud", StreamingLinks["soundcloud"])
    // myForm.append(`podcastToggle`, StreamingLinks.podcastToggle || false)
    // if (typeof StreamingLinks.podcastYoutube     != "undefined" && StreamingLinks.podcastYoutube     != null) myForm.append(`podcastYoutube`,     StreamingLinks.podcastYoutube);
    // if (typeof StreamingLinks.podcastSpotify     != "undefined" && StreamingLinks.podcastSpotify     != null) myForm.append(`podcastSpotify`,     StreamingLinks.podcastSpotify);
    // if (typeof StreamingLinks.podcastApple      != "undefined" && StreamingLinks.podcastApple      != null) myForm.append(`podcastApple`,      StreamingLinks.podcastApple);
    // if (typeof StreamingLinks.podcastAmazonMusic != "undefined" && StreamingLinks.podcastAmazonMusic != null) myForm.append(`podcastAmazonMusic`, StreamingLinks.podcastAmazonMusic);

    const res = await UpdateSong(myForm);
    toastAlert(res.success ? "Song Updated" : "Error in updating Song", res.success ? "success" : "error");

    if (res.success && res.newSlug) router(`/admin/edit-song/${res.newSlug.slug}`)

    hideLoader()

    getSongsAndAlbums()
    scrollToElement("releases-songs-content", _id, "Song element not found!");
  }

  // Delete song
  // yaad rahe jb song delete hoga to uski link all albums main say bhi delete kerni hay backend say
  const deleteData = async (e) => {
    // if (!window.confirm("Do you really want to delete this song")) return;

    e.preventDefault();
    showLoader()

    // yaad rahe jb song delete hoga to uski link all albums main say bhi delete kerni hay backend say
    const res = await DeleteSong({ _id })
    toastAlert(res.message, res.success ? "success" : "error")
    console.log("res.message", res.message);
    hideLoader()

    if (res.success) {
      navigate(`/admin/add-new`)
      getSongsAndAlbums()
    }
  };

  // Check and Uncheck Ablums
  const checkboxHandler = (e) => {
    let checkboxArr = Album;
    if (e.target.checked) {
      checkboxArr = [...Album, e.target.value];

    } else {
      checkboxArr.splice(Album.indexOf(e.target.value), 1);

    }
    console.log(checkboxArr);
    setAlbum(checkboxArr);
    forceUpdate();
  };

  // Link albums with song
  const linkAlbumWithSong = async (e) => {
    e.preventDefault();

    console.log("Album 0000 -> ", Album);
    if (Album.length === 0) {
      toastAlert('Link Atleast One Album!', "error")
      return
    }


    showLoader()

    const res = await LinkAlbumWithSong(_id, Album);
    toastAlert(res.message, res.success ? "success" : "error")

    window.document.getElementById(`artists-content`).classList.add("show");
    window.document.getElementById(`album-project-content`).classList.remove("show");
    moveElementOnScreenTop("#accordionAdminSettings > form:nth-child(2) > section");
    window.document.getElementById(`primaryArtist`).focus();

    hideLoader()
  }

  // Update Streaming Links 
  const updateStreamingLinks = async (e) => {
    e.preventDefault();
    showLoader()

    const myForm = new FormData()
    myForm.append("_id", _id)
    myForm.append("slug", songData.slug)
    myForm.append("youtube", StreamingLinks["youtube"])
    myForm.append("spotify", StreamingLinks["spotify"])
    myForm.append("appleMusic", StreamingLinks["apple-music"])
    myForm.append("tidal", StreamingLinks["tidal"])
    myForm.append("amazonMusic", StreamingLinks["amazon-music"])
    myForm.append("soundCloud", StreamingLinks["soundcloud"])

    const res = await UpdateSong(myForm);
    toastAlert(res.success ? "Streaming links updated" : "Error in updating Streaming links", res.success ? "success" : "error");

    if (res.success) router(`/admin/edit-song/${res.newSlug.slug}`)

    window.document.getElementById(`credits-links-content`).classList.add("show");
    window.document.getElementById(`streaming-links-content`).classList.remove("show");
    moveElementOnScreenTop("#accordionAdminSettings > form:nth-child(4) > section")
    window.document.getElementById(`creditsType1`).focus();

    hideLoader()
  }


  // Fetch All Data 
  const getData = async () => {
    showLoader()
    // console.log("hash", hash);

    const res = await getAllAlbumsName();
    const albumData = res.message;
    if (res.success == false) return;

    const SortAlbums = sortAlbums(albumData)
    function sortAlbums(albums) {
      // Filter out the "Singles" album
      const singlesAlbum = albums.find(album => album.title.toLowerCase() === "single album");

      // Filter out other albums excluding the "Singles" album
      const otherAlbums = albums.filter(album => album.title.toLowerCase() !== "single album");

      // Sort the other albums alphabetically by title
      otherAlbums.sort((a, b) => a.title.localeCompare(b.title));

      // Add the "Singles" album at the beginning
      if (singlesAlbum) {
        otherAlbums.unshift(singlesAlbum);
      }

      return otherAlbums;
    }


    console.log("SortAlbums", SortAlbums);

    const extractedAlbumData = SortAlbums.map((e) => {
      return { name: e.title, id: e._id };
    });

    setallAlbums(extractedAlbumData);

    // agr song add kia hay is page main or url hash main song id hay to song ka data fetch kerre ga


    if (typeof songID != "undefined" && songID != null && songID != "") {
      // const songID = hash.split("#")[1];
      const song = await GetSongData(songID, { type: user?.username });

      console.log("user 345", user)
      console.log("song 345", song)


      if ((pathname == '/admin/edit-song/') && (!song.success)) {
        toastAlert('Invalid Request', "error");
        // navigate('/admin/add-new')
        return
      }

      if (song.success) {
        console.log(song.message, 'abcdefg');
        setsongData(song.message)
        set_id(song.message?._id)
        setTitle(song.message?.title)
        setType(song.message?.type)
        setArtwork({ name: song.message?.img })
        setFileName({ name: song.message?.img })
        setStreamingLinks({
          "youtube": song.message?.youtube != "null" ? song.message?.youtube : "",
          "spotify": song.message?.spotify != "null" ? song.message?.spotify : "",
          "apple-music": song.message?.appleMusic != "null" ? song.message?.appleMusic : "",
          "tidal": song.message?.tidal != "null" ? song.message?.tidal : "",
          "amazon-music": song.message?.amazonMusic != "null" ? song.message?.amazonMusic : "",
          "soundcloud": song.message?.soundCloud != "null" ? song.message?.soundCloud : "",
          "podcastToggle": song.message?.podcastToggle != "null" ? song.message?.podcastToggle : false,
          "podcastYoutube": song.message?.podcastYoutube != "null" ? song.message?.podcastYoutube : "",
          "podcastSpotify": song.message?.podcastSpotify != "null" ? song.message?.podcastSpotify : "",
          "podcastApple": song.message?.podcastApple != "null" ? song.message?.podcastApple : "",
          "podcastAmazonMusic": song.message?.podcastAmazonMusic != "null" ? song.message?.podcastAmazonMusic : "",
        })

        const songAlbums = song.message?.albums;
        const songAlbumIds = songAlbums?.map(e => e._id);
        console.log(songAlbumIds, 'songAlbumIds');
        if ((songAlbumIds != 'null') && (songAlbumIds?.length > 0)) {
          console.log(songAlbumIds, 'songAlbumIds');
          setAlbum(songAlbumIds)
        } else {
          setAlbum([])
        }
      }
    }

    const res2 = await GetAllArtists({ nameonly: true })
    console.log("GetAllArtists -> ", res2.message);

    const temp = (res2.message)//.map((e) => (e.name))


    function getUniqueArtists(artists) {
      // Create a Set to store unique names
      const uniqueNames = new Set();

      // Filter out duplicates and convert names to lowercase for case-insensitivity
      const uniqueArtists = artists.filter(artist => {
        const lowercaseName = artist.name.toLowerCase();
        if (!uniqueNames.has(lowercaseName)) {
          uniqueNames.add(lowercaseName);
          return true;
        }
        return false;
      });

      return uniqueArtists;
    }


    var unique = getUniqueArtists(temp);
    console.log('Unique Artist Name');
    console.log(unique);

    setArtistNameList(unique)

    hideLoader()

  };


  const handleCancelStreaming = async () => {
    document.getElementById('streaming-links-content-btn').classList.add('collapsed')

    const song = await GetSongData(songID);
    if (song.success) {
      setStreamingLinks({
        "youtube": song.message?.youtube != "null" ? song.message?.youtube : "",
        "spotify": song.message?.spotify != "null" ? song.message?.spotify : "",
        "apple-music": song.message?.appleMusic != "null" ? song.message?.appleMusic : "",
        "tidal": song.message?.tidal != "null" ? song.message?.tidal : "",
        "amazon-music": song.message?.amazonMusic != "null" ? song.message?.amazonMusic : "",
        "soundcloud": song.message?.soundCloud != "null" ? song.message?.soundCloud : "",
        "podcastToggle": song.message?.podcastToggle != "null" ? song.message?.podcastToggle : false,
        "podcastYoutube": song.message?.podcastYoutube != "null" ? song.message?.podcastYoutube : "",
        "podcastSpotify": song.message?.podcastSpotify != "null" ? song.message?.podcastSpotify : "",
        "podcastApple": song.message?.podcastApple != "null" ? song.message?.podcastApple : "",
        "podcastAmazonMusic": song.message?.podcastAmazonMusic != "null" ? song.message?.podcastAmazonMusic : "",
      })
    } else {
      setStreamingLinks(streamingLinksTemplate)
    }
  }

  const handleAlbumCancel = async () => {
    document.getElementById('album-project-content-btn').classList.add('collapsed')

    const song = await GetSongData(songID);
    const songAlbums = song.message?.albums;
    const songAlbumIds = songAlbums?.map(e => e._id);
    if ((songAlbumIds != 'null') && (songAlbumIds?.length > 0)) {
      setAlbum(songAlbumIds)
    } else {
      setAlbum([])
    }
  }

  useEffect(() => {
    getData();
  }, [songID]);




  return (
    <>
      <DashHead />

      <div className="container-admin">
        <div className="row mb-5">
          <div className="col-lg-4 position-relative">
            <Sidebar />
          </div>
          <div className="col-lg">
            {pathname.includes('/admin/edit-song/') ? <h2>Edit Song</h2> : <h2>Add New Song</h2>}


            <form onSubmit={_id ? (e) => updateSong(e) : (e) => submitData(e)} className="d-grid gap-5 my-3" encType="multipart/form-data" >
              <section className="d-grid gap-3">

                <div className="d-flex justify-content-between">
                  {_id && <button type="button" className="btn btn-outline-danger" data-bs-toggle="modal" data-bs-target="#deleteASongModal"> Delete Song </button>}
                  <div className="d-flex gap-3 justify-content-end">
                    <Link to={"/admin/add-new"} aria-label="Cancel" className="btn btn-secondary" > Cancel </Link>
                    <button type="submit" className="btn btn-primary"> Save </button>
                  </div>
                </div>

                <div className="form-floating">
                  <input type="text" className="form-control" id="title" name="title" aria-label="Add Song Title" value={Title} onChange={(e) => setTitle(e.target.value)} placeholder="Title" required />
                  <label for="title">Title</label>
                </div>

                <div class="form-floating">
                  <select class="form-select" id={`type`} name={`type`} aria-label="Select Song Type" value={Type} onChange={(e) => setType(e.target.value)} required  >
                    <option value="lyrical" selected> Lyrical </option>
                    <option value="instrumental">Instrumental</option>
                  </select>
                  <label for={`type`}>Type</label>
                </div>

                <div class="input-group">
                  <input type="file" className="form-control" id="artworkPhoto" aria-label="Add Song Artwork" onChange={(e) => setArtwork(e.target.files[0])} />
                  {/* <label className="input-group-text" for="artworkPhoto">
                    <span className="icon-photo"></span>
                    {!Artwork ? "Upload your Artwork" : Artwork?.name}
                  </label> */}
                  <ImageComp myfile={Artwork} setmyfile={setArtwork} setFileName={setFileName} text={'Upload your Artwork'} forr={'artworkPhoto'} />

                </div>

              </section>
            </form>

            {
              _id && <>
                <div className="accordion d-grid gap-4 ps-3" id="accordionAdminSettings" >

                  <form onSubmit={(e) => linkAlbumWithSong(e)}>
                    <section className="accordion-item d-grid shadow rounded-3">
                      <fieldset>
                        <legend className="accordion-header accordion-sticky-header sticky-top rounded-3">
                          <h3>
                            <button id="album-project-content-btn" className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#album-project-content" aria-expanded="true" aria-controls="album-project-content" > Album / Project </button>
                          </h3>
                        </legend>

                        <div id="album-project-content" class="accordion-collapse collapse show" data-bs-parent="#accordionAdminSettings" >
                          <div class="accordion-body">
                            <div className="d-grid gap-3 px-3">
                              <div className="d-flex gap-3 py-3 justify-content-end border-top sticky-top accordion-sticky-actions">
                                <button type="button" aria-label="Cancel" className="btn btn-secondary" data-bs-toggle="collapse" data-bs-target="#album-project-content" aria-expanded="true" aria-controls="album-project-content" onClick={handleAlbumCancel}> Cancel </button>
                                <button type="submit" className="btn btn-primary" data-bs-target="#submit-album-form" aria-expanded="true" aria-controls="submit-album-form"> Save and Continue </button>
                              </div>

                              {
                                allAlbums.map((e, i) => {
                                  return (
                                    <>
                                      {Album.includes(e.id.toString()) ? (
                                        <div key={i} class="form-check">
                                          <input class="form-check-input" aria-label={`Uncheck Album ${i + 1}`} disabled={allAlbums.length < 2} checked={true} value={e.id} type="checkbox" id={`album${i + 1}Check`} onChange={(e) => checkboxHandler(e)} />
                                          <label class="form-check-label" for={`album${i + 1}Check`} > {e.name}</label>
                                        </div>
                                      ) : (
                                        <div key={i} class="form-check">
                                          <input class="form-check-input" aria-label={`Check Album ${i + 1}`} checked={false} value={e.id} type="checkbox" id={`album${i + 1}Check`} onChange={(e) => checkboxHandler(e)} />
                                          <label class="form-check-label" for={`album${i + 1}Check`} > {e.name} </label>
                                        </div>
                                      )}
                                    </>
                                  );
                                })
                              }

                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </section>
                  </form>

                  <Artists allArtistsData={songData?.artists || []} data={artistNameList} GetAllArtists={GetAllArtists} setArtistNameList={setArtistNameList} />

                  <form onSubmit={(e) => updateStreamingLinks(e)}>
                    <section className="accordion-item d-grid shadow rounded-3">
                      <fieldset>
                        <legend className="accordion-header accordion-sticky-header sticky-top rounded-3">
                          <h3>
                            <button id="streaming-links-content-btn" className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#streaming-links-content" aria-expanded="true" aria-controls="streaming-links-content" >Streaming Links </button>
                          </h3>
                        </legend>

                        <div id="streaming-links-content" class="accordion-collapse collapse" data-bs-parent="#accordionAdminSettings" >
                          <div class="accordion-body">
                            <div id="Streaming Links" className="d-grid gap-3 px-3">
                              <div className="d-flex gap-3 justify-content-end py-3 border-top sticky-top accordion-sticky-actions">
                                <button type="button" className="btn btn-secondary" aria-label="Cancel" onClick={handleCancelStreaming} data-bs-toggle="collapse" data-bs-target="#streaming-links-content" aria-expanded="true" aria-controls="streaming-links-content"> Cancel </button>
                                <button type="submit" className="btn btn-primary"> Save and Continue </button>
                              </div>

                              {
                                socialLinks.map((e, i) => {
                                  return (
                                    <div key={i} className={`form-floating form-social social-icon-${e.icon}`} >
                                      <input type="text" class="form-control" aria-label={`Add ${e.label} Link`} id={e.icon} value={StreamingLinks?.[e.icon]} onChange={(E) => setStreamingLinks({ ...StreamingLinks, [e.icon]: E.target.value, })} placeholder={e.label} />
                                      <label for={e.icon}>{e.label}</label>
                                    </div>
                                  );
                                })}

                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </section>
                  </form>

                  <Credits allCreditsData={songData?.credits || []} data={data} />

                  <Lyrics allLyricsData={songData?.lyrics || []} />

                  <Video />

                </div>
              </>
            }
          </div>
        </div>
      </div>



      <div class="modal fade" id="deleteASongModal" tabindex="-1" aria-labelledby="deleteASongModalLabel" aria-hidden="true" >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h2 class="modal-title" id="deleteASongModalLabel"> Confirm Delete </h2>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
            </div>
            <div class="modal-body">
              <div className="d-grid gap-3">
                <p>Are you sure you want to permanently delete the song “{Title}”?
                  <br /> <br />
                  This can’t be undone!</p>
              </div>
            </div>
            <div class="modal-footer gap-2">
              <button to={"/admin/add-new"} data-bs-dismiss="modal" aria-label="No" className="btn btn-secondary" > No </button>
              <button className="btn btn-primary" data-bs-dismiss="modal" aria-label="Yes" onClick={(e) => deleteData(e)}> Yes </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};



export default AddSong;