// import './App.css';
import './scss/custom-variables.scss';
import './scss/custom.scss';
// import './css/bootstrap.min.c 1ss'

import { Navigate, Route, Routes } from 'react-router-dom';
import Homepage from './pages/Homepage';
import Project from './pages/Project';
import Song from './pages/Song';
import SignUp from './pages/SignUp';
import ForgotPass from './pages/ForgotPass';
import CompleteProfile from './pages/CompleteProfile';
import SignIn from './pages/SignIn';
import AdminDashboard from './pages/Dashboard Pages/AdminDashboard';
import AdminLayout from './pages/Dashboard Pages/AdminLayout';
import EmailSignUp from './pages/Dashboard Pages/EmailSignUp';
import PromotionalCallToAction from './pages/Dashboard Pages/PromotionalCallToAction';
import PromotionalVideo from './pages/Dashboard Pages/PromotionalVideo';
import AdminEmail from './pages/Dashboard Pages/AdminEmail';
import AdminPassword from './pages/Dashboard Pages/AdminPassword';
import SocialLinks from './pages/Dashboard Pages/SocialLinks';
import AddNew from './pages/Dashboard Pages/AddNew';
import AddSong from './pages/Dashboard Pages/AddSong';

import { useToast } from './ContextAPI/Components/notify';
import { useEffect } from 'react';
import AddAlbum from './pages/Dashboard Pages/AddAlbum';
import EditSong from './pages/Dashboard Pages/EditSong';
import { useAuth } from './ContextAPI/Components/auth';
import axios from 'axios';
import { useLoader } from './ContextAPI/Components/loaderContext';
import Loader from './components/dashboard-components/loader';
import EditAlbum from './pages/Dashboard Pages/EditAlbum';



function App() {
  const Url = window.location.origin
  const { getLoginUser, user } = useAuth();
  const { loader } = useLoader()
  const { showNotify, cleartoastAlert } = useToast()
  const token = JSON.parse(localStorage.getItem("id")) || null;
  axios.defaults.headers.common['pk2'] = token;


  useEffect(() => {
    getLoginUser();

    if (Url !== 'http://localhost:5000') {
      console.log = () => { }
    }

  }, [])



  return (
    <>

      {
        loader && <Loader />
      }


      <div className="toast-container p-5 position-fixed top-0 start-50 translate-middle-x">

        {
          showNotify.msg != null &&
          <div className={`toast show align-items-center ${showNotify.type === "success" ? 'text-bg-success' : 'text-bg-danger'} border-0`} role="alert" aria-live="assertive" aria-atomic="true">
            <div className="d-flex">
              <div className="toast-body">
                {showNotify?.msg}
              </div>
              <button type="button" className="btn-close btn-close-white p-4 me-2 m-auto" onClick={() => cleartoastAlert()} data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
          </div>
        }

      </div>

      <Routes>
        <Route exact path='/' element={<Homepage />} />
        <Route exact path='/project/:id' element={<Project />} />
        <Route exact path='/song/:id' element={<Song />} />

        <Route exact path='/sign-in' element={<SignIn />} />
        <Route exact path='/sign-up' element={<SignUp />} />
        <Route exact path='/forgot-password' element={<ForgotPass />} />
        <Route exact path='/complete-profile/:id' element={<CompleteProfile />} />

        {
          token != null ?
            <>
              <Route exact path='/admin/dashboard' element={<AdminDashboard />} />
              <Route exact path='/admin/email' element={<AdminEmail />} />
              <Route exact path='/admin/password' element={<AdminPassword />} />
              <Route exact path='/admin/layout' element={<AdminLayout />} />
              <Route exact path='/admin/social-links' element={<SocialLinks />} />
              <Route exact path='/admin/email-sign-up' element={<EmailSignUp />} />
              <Route exact path='/admin/promotional-call-to-action' element={<PromotionalCallToAction />} />
              <Route exact path='/admin/promotional-video' element={<PromotionalVideo />} />
              <Route exact path='/admin/add-new' element={<AddNew />} />
              <Route exact path='/admin/add-new-song' element={<AddSong />} />
              <Route exact path='/admin/add-new-album' element={<AddAlbum />} />
              <Route exact path='/admin/edit-song/:songID' element={<EditSong />} />
              <Route exact path='/admin/edit-album/:albumID' element={<EditAlbum />} />
            </>
            :
            <Route path='*' element={<Navigate to="/" replace={true} />} />
        }
      </Routes>
    </>
  );
}


export default App;
