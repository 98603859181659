import React from 'react'
import Sidebar from '../../components/dashboard-components/Sidebar'
import DashHead from '../../components/dashboard-components/dashHead'


import { updatePassword } from '../../ContextAPI/APIs/api';
import { useState } from 'react';
import { useToast } from '../../ContextAPI/Components/notify';
import { useLoader } from '../../ContextAPI/Components/loaderContext';


const AdminPassword = () => {
    const { toastAlert } = useToast();
    const { showLoader, hideLoader } = useLoader();

    const [password, setpassword] = useState(null)
    const [oldpass, setoldpass] = useState(null)
    const [confPass, setconfPass] = useState(null)




    const update = async (e) => {
        e.preventDefault();
        showLoader()
        if (confPass !== password) {
            hideLoader()
            return toastAlert("Password doesn't match", "error")
        }

        const res = await updatePassword({ oldpass, password })
        toastAlert(res.message, res.success ? "success" : "error")
        hideLoader()
    }

    return <>
        <section>

            <DashHead />

            <div className="container-admin">


                <div className="row mb-5">
                    <div className="col-lg-4">
                        <Sidebar />
                    </div>
                    <div className="col-lg">
                        <h2 className=''>Password</h2>
                        <form onSubmit={(e) => update(e)} className="d-grid gap-3 my-3">

                            <div className="form-floating">
                                <input type="password" className='form-control' id='currentPassword' name='currentPassword' value={oldpass} onChange={(e) => setoldpass(e.target.value)} placeholder='Please Enter Your Current Password' required />
                                <label for='currentPassword'>Please Enter Your Current Password</label>
                            </div>
                            <div className="form-floating">
                                <input type="password" className='form-control' id='newPassword' name='newPassword' value={password} onChange={(e) => setpassword(e.target.value)} placeholder='Please Enter Your New Password' required />
                                <label for="newPassword">Please Enter Your New Password</label>
                            </div>
                            <div className="form-floating">
                                <input type="password" className='form-control' id='confirmPassword' name='confirmPassword' value={confPass} onChange={(e) => setconfPass(e.target.value)} placeholder='Confirm New Password' required />
                                <label for="confirmPassword">Confirm New Password</label>
                            </div>
                            <button className='btn btn-primary'>Save</button>
                        </form>
                    </div>
                </div>
            </div>

        </section>
    </>
}

export default AdminPassword
