import { createContext, useContext, useState } from "react"


// Step 1
const CreditorsArtistContext = createContext()

// Step 2
export const useCreditorsArtist = () => {
    return useContext(CreditorsArtistContext);
}

// Step 3
export const CreditorsArtistProvider = ({ children }) => {

    const [CreditorsArtistList, setCreditorsArtistList] = useState([]);

    return (
        <CreditorsArtistContext.Provider value={{ CreditorsArtistList, setCreditorsArtistList }}>
            {children}
        </CreditorsArtistContext.Provider>
    )
}