import React, { useEffect, useReducer, useState } from "react";
import Sidebar from "../../components/dashboard-components/Sidebar";
import DashHead from "../../components/dashboard-components/dashHead";
import { Link, useNavigate } from "react-router-dom";
import { GetAllSongs, createAlbum } from "../../ContextAPI/APIs/api";
import { useToast } from '../../ContextAPI/Components/notify';
import { useLoader } from "../../ContextAPI/Components/loaderContext";
import ImageComp from "../../components/dashboard-components/ImageComp";
import { useSidebar } from "../../ContextAPI/Components/sidebar";




const AddAlbum = () => {
  const navigate = useNavigate();
  const { toastAlert } = useToast();
  const { getSongsAndAlbums } = useSidebar();
  const { loader, showLoader, hideLoader } = useLoader()


  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const [Title, setTitle] = useState("");
  const [Artwork, setArtwork] = useState(null);
  const [StreamingLinks, setStreamingLinks] = useState({
    socialLabel: "",
    youtube: "",
    spotify: "",
    "apple-music": "",
    tidal: "",
    "amazon-music": "",
    soundcloud: "",
  });

  let socialLinks = [
    {
      icon: "youtube",
      label: "Youtube",
    },
    {
      icon: "spotify",
      label: "Spotify",
    },
    {
      icon: "apple-music",
      label: "Apple Music",
    },
    {
      icon: "tidal",
      label: "Tidal",
    },
    {
      icon: "amazon-music",
      label: "Amazon Music",
    },
    {
      icon: "soundcloud",
      label: "Soundcloud",
    },
  ];

  const [songs, setsongs] = useState([]);
  const [filteredSongs, setfilteredSongs] = useState([])

  const [addedSongs, setaddedSongs] = useState([]);

  const filterSong = (value) => {
    if (value) {
      const found = songs.filter((e) => e.title.toLowerCase().includes(value.toLowerCase()));
      setfilteredSongs(found)
    } else {
      setfilteredSongs(songs)
    }
  }

  const AddSong = (data) => {
    const foundSong = addedSongs.filter((e) => e.song == data);

    console.log(foundSong);

    if (foundSong.length > 0) return;
    else
      setaddedSongs([...addedSongs, { id: addedSongs.length + 1, song: data }]);
  };

  const contentDown = (i, arr, setArr, name) => {
    const temp = arr;

    // console.log(temp);

    let index1Value = temp[i];
    let index2Value = temp[i + 1];
    let index1id = temp[i].id;
    let index2id = temp[i + 1].id;

    temp[i] = index2Value;
    temp[i + 1] = index1Value;

    temp[i].id = index1id;
    temp[i + 1].id = index2id;

    console.log(temp);
    setArr(temp);

    if (i == temp.length - 2) {
      window.document.getElementById(`${name}Top${temp.length - 1}`).focus();
    } else {
      window.document.getElementById(`${name}Down${i + 1}`).focus();
    }

    forceUpdate();
  };

  const contentUp = (i, arr, setArr, name) => {
    const temp = arr;

    // console.log(temp);

    let index1Value = temp[i];
    let index2Value = temp[i - 1];
    let index1id = temp[i].id;
    let index2id = temp[i - 1].id;

    temp[i] = index2Value;
    temp[i - 1] = index1Value;
    temp[i].id = index1id;
    temp[i - 1].id = index2id;

    console.log(temp);
    setArr(temp);

    if (i == 1) {
      window.document.getElementById(`${name}Down0`).focus();
    } else {
      window.document.getElementById(`${name}Top${i - 1}`).focus();
    }

    forceUpdate();
  };

  const contentDelete = (i, arr, setArr) => {
    const temp = arr;

    temp.splice(i, 1);

    setArr(temp);

    forceUpdate();
  };

  const submitData = async (e) => {
    e.preventDefault();
    showLoader()

    const form = new FormData();
    form.append("title", Title);
    form.append("myfile", Artwork);
    form.append("youtube", StreamingLinks.youtube);
    form.append("spotify", StreamingLinks.spotify);
    form.append("appleMusic", StreamingLinks["apple-music"]);
    form.append("tidal", StreamingLinks.tidal);
    form.append("amazonMusic", StreamingLinks["amazon-music"]);
    form.append("soundcloud", StreamingLinks.soundcloud);

    const res = await createAlbum(form);
    console.log(res);

    toastAlert(res.message, res.success ? "success" : "error")

    setTitle("");
    setArtwork("");
    setaddedSongs([]);
    setStreamingLinks({
      socialLabel: "",
      youtube: "",
      spotify: "",
      "apple-music": "",
      tidal: "",
      "amazon-music": "",
      soundcloud: "",
    });

    hideLoader()

    navigate(`/admin/edit-album/${res.slug}`)

    getSongsAndAlbums()
  };

  const getSongs = async (e) => {
    const songsList = await GetAllSongs()
    console.log("songsList", songsList);
    const songsData = (songsList.message).map(e => {
      return { id: e._id, title: e.title }
    })
    setsongs(songsData)
    setfilteredSongs(songsData)
  }

  useEffect(() => {
    getSongs()
  }, [])



  return (
    <>
      <DashHead />

      <div className="container-admin">
        <div className="row mb-5 ">
          <div className="col-4 position-relative">
            <Sidebar />
          </div>
          <div className="col-8">
            <h2>Add New Album / Project</h2>
            <form onSubmit={(e) => submitData(e)} className="d-grid gap-5 my-3" encType="multipart/form-data" >
              <section className="d-grid gap-3">
                <div className="d-flex gap-3 justify-content-end">
                  <Link to={"/admin/add-new"} aria-label="Cancel" className="btn btn-secondary" > Cancel </Link>
                  <button className="btn btn-primary" aria-label="Save"> Save </button>
                </div>

                <div className="form-floating">
                  <input type="text" className="form-control" aria-label="Add Album Title" id="title" name="title" value={Title} onChange={(e) => setTitle(e.target.value)} placeholder="Title" required />
                  <label for="title">Title</label>
                </div>

                <div class="input-group overflow-hidden">
                  <input type="file" className="form-control" aria-label="Upload Album Artwork" id="artworkPhoto" onChange={(e) => setArtwork(e.target.files[0])} />
                  <ImageComp myfile={Artwork} setmyfile={setArtwork} text={'Upload your Artwork'} forr={'artworkPhoto'} />
                </div>
              </section>

              <section className="d-grid gap-3">
                <h3>Streaming Links</h3>

                <div id="Streaming Links" className="ps-4">
                  {socialLinks.map((e, i) => {
                    return (
                      <div key={i} className={`form-floating form-social mb-3 social-icon-${e.icon}`} >
                        <input
                          type="text" class="form-control" aria-label={`Add ${e.label} Link`} id={e.icon} placeholder={e.label}
                          value={StreamingLinks?.[e.icon]}
                          onChange={(E) => setStreamingLinks({ ...StreamingLinks, [e.icon]: E.target.value, })}
                        />
                        <label for={e.icon}>{e.label}</label>
                      </div>
                    );
                  })}
                </div>
              </section>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};



export default AddAlbum;