import { createContext, useContext, useState } from "react"
import { GetAllSongs, getAllAlbumsName } from "../APIs/api";
import { useLoader } from "./loaderContext";


// Step 1
const SidebarContext = createContext()

// Step 2
export const useSidebar = () => {
    return useContext(SidebarContext);
}

// Step 3
export const SidebarProvider = ({ children }) => {

    const [songs, setsongs] = useState([])
    const [Albums, setAlbums] = useState([])
    const { showLoader, hideLoader } = useLoader()

    const getSongsAndAlbums = async () => {
        const songsList = await GetAllSongs()
        if (songsList.success) {
            const songsData = (songsList.message).map(e => {
                return { id: e._id, title: e.title, slug: e.slug }
            })


            const songsSorted = songsData.toSorted((a, b) => {
                if (a.title < b.title) {
                    return -1;
                }
                if (a.title > b.title) {
                    return 1;
                }
                return 0;
            })


            setsongs(songsSorted)

            const AlbumsList = await getAllAlbumsName();
            if (songsList.success) {
                const AlbumsData = (AlbumsList.message).map(e => {
                    return { id: e._id, title: e.title, slug: e.slug }
                })
                setAlbums(AlbumsData)
            }

        }
    }


    return (
        <SidebarContext.Provider value={{ songs, setsongs, Albums, setAlbums, getSongsAndAlbums }}>
            {children}
        </SidebarContext.Provider>
    )
}