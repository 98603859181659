import { useEffect, useReducer, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { GetSongData, UpdateSong, getAllAlbum, } from "../../../ContextAPI/APIs/api";
import { useToast } from "../../../ContextAPI/Components/notify"
import { useLoader } from "../../../ContextAPI/Components/loaderContext";
import { moveElementOnScreenTop } from "../../../helpers/util";




const Lyrics = ({ allLyricsData }) => {

    const { songID } = useParams(); // basically it's a Slug not ID
    const navigate = useNavigate();
    const { hash, pathname } = useLocation();
    const { toastAlert } = useToast();
    const { loader, showLoader, hideLoader } = useLoader()

    if ((pathname == '/admin/edit-song/' || pathname == '/admin/edit-song') && (!songID)) {
        toastAlert('Invalid Request', "error");
        navigate('/admin/add-new')
    }

    const [, forceUpdate] = useReducer((x) => x + 1, 0);


    const lyricsTemplate = {
        id: 1,
        type: "intro",
        lyric: "",
    };

    const [lyricsData, setlyricsData] = useState([lyricsTemplate]);

    const lyricsTypeValueHandler = (e, i) => {
        const updatedData = lyricsData.map((item, index) => {
            if (index === i) {
                return { ...item, type: e.target.value };

            } else {
                return item;

            }
        });

        setlyricsData(updatedData);
    };

    const lyricsContentValueHandler = (e, i) => {
        const updatedData = lyricsData.map((item, index) => {
            if (index === i) {
                return { ...item, lyric: e.target.value };

            } else {
                return item;

            }
        });

        setlyricsData(updatedData);
    }

    const contentDown = (i, arr, setArr, name) => {
        const temp = arr;

        // console.log(temp);

        let index1Value = temp[i];
        let index2Value = temp[i + 1];
        let index1id = temp[i].id;
        let index2id = temp[i + 1].id;

        temp[i] = index2Value;
        temp[i + 1] = index1Value;

        temp[i].id = index1id;
        temp[i + 1].id = index2id;

        console.log(temp);
        setArr(temp);

        if (i == temp.length - 2) {
            window.document.getElementById(`${name}Top${temp.length - 1}`).focus();
        } else {
            window.document.getElementById(`${name}Down${i + 1}`).focus();
        }

        forceUpdate();
    };

    const contentUp = (i, arr, setArr, name) => {
        const temp = arr;

        // console.log(temp);

        let index1Value = temp[i];
        let index2Value = temp[i - 1];
        let index1id = temp[i].id;
        let index2id = temp[i - 1].id;

        temp[i] = index2Value;
        temp[i - 1] = index1Value;
        temp[i].id = index1id;
        temp[i - 1].id = index2id;

        console.log(temp);
        setArr(temp);

        if (i == 1) {
            window.document.getElementById(`${name}Down0`).focus();
        } else {
            window.document.getElementById(`${name}Top${i - 1}`).focus();
        }

        forceUpdate();
    };

    const contentDelete = (i, arr, setArr) => {
        const temp = arr;

        temp.splice(i, 1);

        setArr(temp);

        forceUpdate();
    };

    // Update lyrics of a Song
    const updateLyrics = async (e) => {
        e.preventDefault();

        showLoader()

        const myForm = new FormData()
        myForm.append("slug", songID)
        myForm.append("lyrics", JSON.stringify(lyricsData))

        const res = await UpdateSong(myForm);
        toastAlert(res.success ? "Lyrics updated" : "Error in updating Lyrics", res.success ? "success" : "error");

        hideLoader()

        window.document.getElementById(`videos-links-content`).classList.add("show");
        window.document.getElementById(`lyrics-links-content`).classList.remove("show");
        moveElementOnScreenTop("#accordionAdminSettings > form:nth-child(6) > section");
        window.document.getElementById(`videoType1`).focus();

    }

    // Fetch All Data 
    const getData = async () => {
        showLoader()

        if (typeof songID != "undefined" && songID != null && songID != "") {
            const song = await GetSongData(songID);

            if ((pathname == '/admin/edit-song/') && (!song.success)) {
                toastAlert('Invalid Request', "error");
                navigate('/admin/add-new')
            }

            if (song.success) {

                if ((song.message.lyrics != 'null') && ((song.message.lyrics).length > 0)) {
                    setlyricsData(song.message.lyrics)
                } else {
                    setlyricsData([lyricsTemplate])
                }
            }
        }

        // const res2 = await GetAllArtists()

        hideLoader()
    };

    const handleCancel = () => {

        document.getElementById('lyrics-links-content-btn').classList.add('collapsed')

        if ((allLyricsData != 'null') && ((allLyricsData).length > 0)) {
            setlyricsData(allLyricsData)
        } else {
            setlyricsData([lyricsTemplate])
        }

    }


    useEffect(() => {

        if ((allLyricsData != 'null') && ((allLyricsData).length > 0)) {
            setlyricsData(allLyricsData)
        } else {
            setlyricsData([lyricsTemplate])
        }

    }, [allLyricsData]);



    return (
        <form onSubmit={(e) => updateLyrics(e)}>
            <section className="accordion-item d-grid shadow rounded-3">
                <fieldset>
                    <legend className="accordion-header accordion-sticky-header sticky-top rounded-3">
                        <h3>
                            <button id="lyrics-links-content-btn" className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#lyrics-links-content" aria-expanded="true" aria-controls="lyrics-links-content" > Lyrics </button>
                        </h3>
                    </legend>

                    <div id="lyrics-links-content" class="accordion-collapse collapse" data-bs-parent="#accordionAdminSettings" >
                        <div class="accordion-body">
                            <div id="Lyrics" className="d-grid gap-3 px-3">
                                <div className="d-flex gap-3 justify-content-end py-3 border-top sticky-top accordion-sticky-actions">
                                    <button type="button" aria-label="Cancel" className="btn btn-secondary" onClick={handleCancel} data-bs-toggle="collapse" data-bs-target="#lyrics-links-content" aria-expanded="true" aria-controls="lyrics-links-content"> Cancel </button>
                                    <button type="submit" className="btn btn-primary"> Save and Continue</button>
                                </div>
                                {lyricsData.map((data, i) => {
                                    return (
                                        <div key={i} id="lyrics-div">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h4>Lyrics Content {i + 1}</h4>
                                                <div>
                                                    <button type="button" className="icon-btn icon-btn-danger" aria-label={`Delete Lyrics ${i + 1}`} onClick={() => contentDelete(i, lyricsData, setlyricsData)}>
                                                        <span className="icon-delete"></span>
                                                    </button>
                                                    <button type="button" id={`lyricsTop${i}`} className={`icon-btn icon-btn-primary`} disabled={i == lyricsData.length - lyricsData.length ? true : false}
                                                        aria-label={`Move Up Lyrics ${i + 1}`} onClick={i == lyricsData.length - lyricsData.length ? () => { return; } : () => contentUp(i, lyricsData, setlyricsData, "lyrics")} >
                                                        <span className="icon-top-arrow"></span>
                                                    </button>
                                                    <button type="button" id={`lyricsDown${i}`} className={`icon-btn icon-btn-primary`} disabled={i == lyricsData.length - 1 ? true : false} aria-label={`Move Down Lyrics ${i + 1}`} onClick={i == lyricsData.length - 1 ? () => { return; } : () => contentDown(i, lyricsData, setlyricsData, "lyrics")} >
                                                        <span className="icon-down-arrow"></span>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="d-grid gap-3">
                                                <div class="form-floating">
                                                    <select class="form-select" aria-label={`Select Lyrics ${i + 1} Type`} id={`lyricsType${i + 1}`} name={`lyricsType${i + 1}`} value={data.type} onChange={(e) => lyricsTypeValueHandler(e, i, data)} required >
                                                        <option value="intro" selected> Intro </option>
                                                        <option value="verse">Verse</option>
                                                        <option value="pre-chorus or pre-hook">Pre-Chorus or Pre-Hook </option>
                                                        <option value="chorus or hook">Chorus or Hook</option>
                                                        <option value="bridge">Bridge</option>
                                                        <option value="outro">Outro</option>
                                                    </select>
                                                    <label for={`lyricsType${i + 1}`}> Type </label>
                                                </div>

                                                <div class="form-floating">
                                                    <textarea class="form-control" aria-label={`Add Lyrics ${i + 1} Content`} placeholder="Content" id="floatingTextarea2" style={{ height: "150px" }} value={data.lyric} onChange={(e) => lyricsContentValueHandler(e, i)} required ></textarea>
                                                    <label for="floatingTextarea2"> Content </label>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}

                                <div className="d-grid gap-3">
                                    <h4>Add Another (Optional)</h4>
                                    <div className="d-grid">
                                        <button type="button" onClick={() => setlyricsData([...lyricsData, { id: lyricsData.length + 1, type: "intro", lyric: "", },])} aria-label={`Add Another Lyrics Optional`} className="btn btn-secondary" > Add Another </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </section>
        </form>
    )
}

export default Lyrics
