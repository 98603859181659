
import logo from '../assets/svg/zintune-logo.svg'
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { signin } from '../ContextAPI/APIs/api';
import { useToast } from '../ContextAPI/Components/notify';
import { useAuth } from '../ContextAPI/Components/auth';
import { useNavigate } from 'react-router-dom';




const SignIn = () => {

    const { toastAlert } = useToast();
    const { setuser } = useAuth();

    const navigate = useNavigate();

    const [email, setemail] = useState('');
    const [password, setpassword] = useState('');


    async function getMyUser(e) {
        e.preventDefault();

        const res = await signin({ email, password })


        toastAlert(res.message, res.success ? "success" : "error")
        if (res.success) {
            localStorage.setItem('id', JSON.stringify(res.hash));
            setuser(res.user);
            return navigate(`/admin/dashboard`)
        }

    }


    useEffect(() => {
        const isAuth = localStorage.getItem("id") ? true : false;
        isAuth && navigate('/admin/dashboard')
    }, [])


    return <>

        <div className="skippy visually-hidden-focusable overflow-hidden">
            <div className="container">
                <a className="d-inline-flex p-2 m-1 link-primary" href="#email">Skip to sign in</a>
            </div>
        </div>

        <div className="container-form text-center py-5">

            <main>
                <section>

                    <Link to={'/sign-in'} className="d-block">
                        <img className="zintune-logo" src={logo} alt="Zin Tune" />
                    </Link>

                    <div id="admin-intro">
                        <span className="h3">Welcome to Zintunes</span>
                        <p>Interactive Platform to create your music journey</p>
                    </div>

                    <h1 aria-describedby="admin-intro">Sign In</h1>

                    <form className="d-grid gap-3" onSubmit={(e) => getMyUser(e)} >
                        <div className="form-floating">
                            <input type="email" className="form-control" value={email} onChange={(e) => setemail(e.target.value)} id="email" placeholder="name@example.com" required />
                            <label for="email">Please enter your email</label>
                        </div>
                        <div className="form-floating">
                            <input type="password" className="form-control" id="password" value={password} onChange={(e) => setpassword(e.target.value)} placeholder="Password" required />
                            <label for="password">Please enter your password</label>
                            <Link to={'/forgot-password'} className="text-end d-block">Forgot Password</Link>
                        </div>
                        <button className="btn btn-primary" type="submit">Sign In</button>
                    </form>
                    <div className="d-grid">
                        <Link to={'/sign-up'} className="btn btn-link">Sign Up</Link>
                    </div>

                </section>
            </main>
            <footer className="my-5">
                <p>© 2023 YNO Designs LLC</p>
            </footer>

        </div>




    </>
}

export default SignIn
