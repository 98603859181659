import React, { useEffect } from 'react'
import Sidebar from '../../components/dashboard-components/Sidebar'
import DashHead from '../../components/dashboard-components/dashHead'
import { useState } from 'react'


import { useToast } from '../../ContextAPI/Components/notify';
import { completeProfile } from '../../ContextAPI/APIs/api';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../ContextAPI/Components/auth';
import ImageComp from '../../components/dashboard-components/ImageComp';
import { useLoader } from '../../ContextAPI/Components/loaderContext';


const AdminDashboard = () => {
    const navigate = useNavigate();

    const { showLoader, hideLoader } = useLoader();
    const { user, getLoginUser } = useAuth();

    const { toastAlert } = useToast()

    const [firstName, setfirstName] = useState(null)
    const [lastName, setlastName] = useState(null)
    const [artistName, setartistName] = useState(null)
    const [username, setusername] = useState(null)
    const [fileName, setFileName] = useState(null)
    const [myfile, setmyfile] = useState(null)



    const submit = async (e) => {
        e.preventDefault();
        showLoader()
        const myData = new FormData();
        myData.append('firstname', firstName);
        myData.append('lastname', lastName);
        myData.append('artistname', artistName);
        myData.append('img', fileName.name != undefined ? fileName.name : '');
        myData.append('myfile', myfile);

        console.log(myData);

        const res = await completeProfile(myData)

        toastAlert(res.message, res.success ? "success" : "error")
        getLoginUser()

        hideLoader()
    }

    useEffect(() => {
        if (user === null) {
            return
        }
        else {
            setfirstName(user.firstname);
            setlastName(user.lastname);
            setartistName(user.artistname)
            setFileName({ name: user.img })
            setmyfile({ name: user.img })
            setusername(user?.username)
        }
    }, [user])


    return <>
        <section>
            <DashHead />

            <div className="container-admin">


                <div className="row mb-5">
                    <div className="col-lg-4">
                        <Sidebar />
                    </div>
                    <div className="col-lg">
                        <h2 className=''>Name and Photo</h2>
                        <form className="d-grid gap-3 my-3" onSubmit={(e) => submit(e)} action="">
                            <div className="form-floating">
                                <input type="text" className='form-control' name="fname" id="fname" placeholder='First Name' value={firstName} onChange={(e) => setfirstName(e.target.value)} required />
                                <label for="fname">First Name</label>
                            </div>
                            <div className="form-floating">
                                <input type="text" className='form-control' name="lname" id="lname" placeholder='Last Name' value={lastName} onChange={(e) => setlastName(e.target.value)} required />
                                <label for="lname">Last Name</label>
                            </div>
                            <div className="form-floating">
                                <input type="text" className='form-control' name="artistName" id="artistName" placeholder='Your Artist Name' value={artistName} onChange={(e) => setartistName(e.target.value)} required />
                                <label for="artistName">Your Artist Name</label>
                            </div>
                            <div className="form-floating">
                                <input type="text" disabled className='form-control' name="username" id="username" placeholder='Your Artist Name' value={username} onChange={(e) => setusername(e.target.value)} required />
                                <label for="username">Username</label>
                            </div>
                            <div class="input-group overflow-hidden">
                                <input type="file" className="form-control" id="profilePhoto" onChange={(e) => setmyfile(e.target.files[0])} />
                                <ImageComp myfile={myfile} setmyfile={setmyfile} setFileName={setFileName} forr={'profilePhoto'} text={'Upload your profile photo (optional)'} />
                            </div>

                            <button className='btn btn-primary'>Save</button>
                        </form>
                    </div>
                </div>
            </div>

        </section>
    </>
}

export default AdminDashboard
