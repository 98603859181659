import { createContext, useContext, useState } from "react"
import { loginUser } from "../APIs/api";


// Step 1
const AuthContext = createContext()

// Step 2
export const useAuth = () => {
    return useContext(AuthContext);
}

// Step 3
export const AuthProvider = ({ children }) => {

    const [user, setuser] = useState(null);

    const getLoginUser = async (body) => {
        const res = await loginUser()
        // console.log(res);

        if (res.success) {
            setuser(res.message)
            localStorage.setItem('id', JSON.stringify(res.hash))
            return res.message
        }

    }

    return (
        <AuthContext.Provider value={{ user, setuser, getLoginUser }}>
            {children}
        </AuthContext.Provider>
    )
}