export const moveElementOnScreenTop = (selector) => {
  document
    .querySelector(selector)
    .scrollIntoView({ behavior: "smooth", block: "start" });
};

export const scrollToElement = (containerId, itemId, errorMessage) => {
  const accordionElement = document.getElementById(containerId);
  accordionElement.classList.add("show");

  const itemElement = Array.from(
    accordionElement.querySelectorAll("ul li a")
  ).find((a) => a.getAttribute("id").includes(itemId));

  if (itemElement) {
    const itemElementRect = itemElement.getBoundingClientRect();
    const y = itemElementRect.y;

    const sidebarElement = document.querySelector("#dashboard-sidebar");
    const ySidebarElement = sidebarElement.getBoundingClientRect().y;

    sidebarElement.scrollBy({
      top: y - ySidebarElement - 160,
      behavior: "smooth",
    });
    sidebarElement.scrollIntoView();
  } else {
    console.error(errorMessage);
  }
};
