import logo from '../assets/svg/zintune-logo.svg'
import photo from '../assets/svg/photo.svg'

import { completeProfile } from '../ContextAPI/APIs/api';
import { useState } from 'react';
import { useToast } from '../ContextAPI/Components/notify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ImageComp from '../components/dashboard-components/ImageComp';




const CompleteProfile = () => {

    const { toastAlert } = useToast()

    const [firstname, setfirstname] = useState(null)
    const [lastname, setlastname] = useState(null)
    const [artistname, setartistname] = useState(null)
    const [fileName, setFileName] = useState({name: ''})
    const [myfile, setmyfile] = useState(null)

    const navigate = useNavigate();
    const { id } = useParams();

    const submit = async (e) => {


        console.log(fileName, '----------------------');
        e.preventDefault();

        const myData = new FormData();
        myData.append('firstname', firstname);
        myData.append('lastname', lastname);
        myData.append('artistname', artistname);
        myData.append('img', fileName.name != undefined ? fileName.name : '');
        myData.append('myfile', myfile);

        console.log(myData);

        const res = await completeProfile(myData, id)

        toastAlert(res.message, res.success ? "success" : "error")
        if (res.success) {
            return navigate('/sign-in')
        }
    }


    return <>

        <div className="skippy visually-hidden-focusable overflow-hidden">
            <div className="container">
                <a className="d-inline-flex p-2 m-1 link-primary" href="#email">Skip to sign in</a>
            </div>
        </div>

        <div className="container-form text-center py-5">

            <main>
                <section>

                    <Link to={'/sign-in'} className="d-block">
                        <img className="zintune-logo" src={logo} alt="Zin Tune" />
                    </Link>

                    <div id="admin-intro">
                        <span className="h3">Welcome to Zintunes</span>
                        <p>Interactive Platform to create your music journey</p>
                    </div>

                    <h1 aria-describedby="admin-intro">Complete Your Profile</h1>

                    <form className="d-grid gap-3" onSubmit={(e) => submit(e)} >
                        <div className='form-floating p-0'>
                            <input type="text" className='form-control' value={firstname} onChange={(e) => setfirstname(e.target.value)} placeholder='First Name' required />
                            <label htmlFor="floatingInput">First Name</label>
                        </div>
                        <div className='form-floating p-0'>
                            <input type="text" className='form-control' value={lastname} onChange={(e) => setlastname(e.target.value)} placeholder='Last Name' required />
                            <label htmlFor="floatingInput">Last Name</label>
                        </div>
                        <div className='form-floating p-0'>
                            <input type="text" className='form-control' value={artistname} onChange={(e) => setartistname(e.target.value)} placeholder='Your Artist Name' required />
                            <label htmlFor="floatingInput">Your Artist Name</label>
                        </div>
                        <div class="input-group overflow-hidden">
                            <input type="file" className="form-control" id="profilePhoto" onChange={(e) => setmyfile(e.target.files[0])} />
                            <ImageComp myfile={myfile} setmyfile={setmyfile} setFileName={setFileName} forr={'profilePhoto'} text={'Upload your profile photo (optional)'} />
                        </div>

                        <button className="btn btn-primary" type="submit">Submit</button>
                    </form>
                    <div className="d-grid">
                        <Link to={'/sign-in'} className="btn btn-link">Skip</Link>
                    </div>

                </section>
            </main>
            <footer className="my-5">
                <p>© 2023 YNO Designs LLC</p>
            </footer>

        </div>

    </>
}

export default CompleteProfile
